<template>
  <div>
    <div v-if="getCurrentUser.team == undefined || (getCurrentUser.team && getCurrentUser.team.role == 0)">
      <!-- INFO -->
      <p v-if="subEnded" class="currentSubscription">
        {{
          $t("Profile.subscription.subscription_ended", {
            plan: getCurrentUser.usr_subscription.name,
          })
        }}
        {{ displayDate(getCurrentUser.usr_subscription.end) }}
      </p>
      <p v-else-if="getCurrentUser.usr_subscription?.end != null" class="currentSubscription">
        {{
          $t("Profile.subscription.your_subscription_will_end_at", {
            plan: getCurrentUser.usr_subscription.name,
          })
        }}
        {{ displayDate(getCurrentUser.usr_subscription.end) }}
      </p>
      <p v-else-if="currentSubscription != null" class="currentSubscription">
        ✓ {{ $t("Profile.subscription.currently_subscribed_to") }}
        <a>{{ getCurrentUser.usr_subscription.name }}</a>
      </p>

      <!-- SWITCH MONTHLY/ANNUALY -->
      <!--
      <Switch class="planModeSwitch center" :leftChoice_display="$t('Profile.subscription.monthly')" leftChoice="monthly"
        :rightChoice_display="$t('Profile.subscription.yearly')" rightChoice="yearly" v-model="yearlyMode" color="#3eb1c8" borderWidth="1.5px" borderColor="#c1c1c1" />

    -->
      <!-- PLANS -->
      <div class="plans center">

        <!-- Free plan -->
        <planTemplate :currentPlan="currentSubscription" color="#f8ad6d" plan="free" :mode="planMode">
          <template v-slot:title>
            <h2>Free</h2>
          </template>
          <template v-slot:features>
            <p>1 {{ $t('Profile.subscription.plans.user') }}</p>
            <p>{{ $t('Profile.subscription.plans.yopbox_watermark') }}</p>
            <p>{{ $t('Profile.subscription.plans.live_chat') }}</p>
          </template>
        </planTemplate>
        


        <!-- Starter plan -->
        <planTemplate :currentPlan="currentSubscription" color="#47d7ac" plan="starter" :mode="planMode">
          <template v-slot:title>
            <h2>Starter</h2>
          </template>
          <template v-slot:features>
            <p>1 {{ $t('Profile.subscription.plans.user') }}</p>
            <p>{{ $t('Profile.subscription.plans.no_yopbox_watermark') }}</p>
            <p>{{ $t('Profile.subscription.plans.live_chat_priority') }}</p>
          </template>
        </planTemplate>

        <!-- Plan Unlimited -->
        <planTemplate :currentPlan="currentSubscription" color="#c198f6" plan="unlimited" :mode="planMode">
          <template v-slot:title>
            <h2>Entreprise</h2>
          </template>
          <template v-slot:features>
            <p>{{ $t('Profile.subscription.plans.unlimited_users') }}</p>
            <p>{{ $t('Profile.subscription.plans.no_yopbox_watermark') }}</p>
            <p>{{ $t('Profile.subscription.plans.onboarding') }}</p>
            <p>{{ $t('Profile.subscription.plans.team_features') }}</p>
            <p>{{ $t('Profile.subscription.plans.live_chat_plus') }}</p>
          </template>
        </planTemplate>

      </div>
    </div>
    <div v-else>
      <div v-if="getCurrentUser.team.activated" class="currentSubscription">
        <p style="display: inline;">
          ✓ {{ $t("Profile.subscription.currently_subscribed_to") }}
          <a>Unlimited</a>
        </p>
        <p style="display: inline;"> {{ $t("Profile.subscription.viaJohnTeam", {
          name: `${userAdmin.usr_firstname}
                  ${userAdmin.usr_lastname}`
        }) }}</p>

      </div>

      <p v-else class="currentSubscription">
        {{ $t('Profile.subscription.deactivatedFromTeam_l1', {
          name: userAdmin.usr_firstname + " " +
            userAdmin.usr_lastname
        }) }}<br />{{ $t('Profile.subscription.deactivatedFromTeam_l2') }}
      </p>
    </div>
  </div>
</template>

<script>

String.prototype.removeAccents = function () {
  var set = this.normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
  return set;
};

import Vuex from "vuex";
import axios from "axios";
import i18n from 'src/i18n' 
const I18n = i18n.global

//LIBS
import { displayDate } from "../../../../lib/date-time-duration";

//Components
import planTemplate from "./plans/plan_template.vue";


export default {
  data() {
    return {
      yearlyMode: true,
      userAdmin: { usr_firstname: '', usr_lastname: '' },
    };
  },
  components: {
    planTemplate
  },
  mounted() {
    /* if (this.getCurrentUser.
       subscription_interval !=
       undefined && this.currentSubscription != null) {
       this.planMode = this.getCurrentUser = this.getCurrentUser.subscription_interval
     */

    // this.refresh_user_info();

    if (this.$route.query && this.$route.query.message) {
      this.Toaster.success(this.$route.query.message);
      this.$router.push(this.$route.path);
    }

    if (
      this.getCurrentUser.team != undefined ||
      (this.getCurrentUser.team && this.getCurrentUser.team.role == 0)
    ) {
      axios
        .get("/teams/" + this.getCurrentUser.team.id, {
          params: { users: true },
        })
        .then((res) => {
          this.userAdmin = res.data.Users[0];
        });
    }
  },
  methods: {
    ...Vuex.mapActions(["refresh_user_info"]),
    displayDate
  },
  computed: {
    ...Vuex.mapGetters(["getCurrentUser", "subscriptionExpiration"]),
    planMode() {
      return this.yearlyMode ? "yearly" : 'monthly'
    },
    subEnded() {
      if (this.getCurrentUser.usr_subscription?.end == null) {
        return false
      } else {
        if (new Date(this.getCurrentUser.usr_subscription.end) < new Date()) {
          return true
        } else {
          return false
        }
      }
    },
    currentSubscription() {

      if (
        this.getCurrentUser.usr_subscription?.name != undefined &&
        this.getCurrentUser.usr_subscription?.name != null &&
        this.getCurrentUser.usr_subscription.name != ""
      ) {
        if (this.getCurrentUser.usr_subscription.name == 'Free') {
          return null
        }
        return `${this.getCurrentUser.usr_subscription.name}_${this.getCurrentUser.usr_subscription.interval}`;
      } else {
        return null;
      }
    },
    couponCodeLink(){
      const locale = I18n.locale.value
      if (locale == "fr"){
        return "https://www.linkedin.com/posts/julien-yopbox_marre-des-abonnements-payez-une-fois-et-activity-7204056533385555968-BJ7J"
      } else {
        return "https://www.linkedin.com/posts/julien-yopbox_marre-des-abonnements-payez-une-fois-et-activity-7204056533385555968-BJ7J"
      }
    }
  },
};
</script>



<style src="./billing.scss" lang="scss" scoped/>
<style src="./plans/plan.scss" lang="scss" scoped/>
<style lang="scss" scoped>
.gradient_shadow {
        
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        z-index: 11;

        width: calc(var(--onboarder_width)+6px);
        min-width : var(--onboarder_min_width);
        max-width : var(--onboarder_max_width);
        height : calc(var(--onboarder_heigth)+6px);

        opacity: 0.8;
        border-radius: var(--border_radius) var(--border_radius) 0 0 ;
        background: linear-gradient(158deg,#3d3393,#2b76b9 31%,#2cacd1 57%,#35eb93) border-box;
        filter: blur(10px);
}

.lifetime_offer_inner{
  position: relative;
  width:100%;
  height: 100%;
  border-radius: var(--border_radius);

  background: linear-gradient(white, white) padding-box, linear-gradient(158deg,#3d3393,#2b76b9 31%,#2cacd1 57%,#35eb93) border-box;
  background: linear-gradient(158deg,#3d3393,#2b76b9 31%,#2cacd1 57%,#35eb93) border-box;
        

  color: white !important;
  
  
  border: 6px solid transparent;

  z-index: 13; 
  & > *{
    color: white !important;
  }
}
</style>
